import * as React from 'react'

import { Container } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faGithub, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons'

import ReactMarkdown from 'react-markdown'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'

import { aboutIcon, faIconSize, aboutText } from './about.module.css'


const AboutPage = ({ data }) => {
    return (
        <MainLayout pageTitle="About">
            <Seo title="About" description="Info about ai03 Design Studio itself"/>
            <Container className="text-center mb-5">
                <GatsbyImage image={data.strapiAboutpage.icon.localFile.childImageSharp.gatsbyImageData} alt="" className={aboutIcon} loading="eager" />
                <p className={faIconSize} >
                    <a href="https://twitter.com/ai03_2725">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a className="ms-3" href="https://www.instagram.com/ai03_2725/">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a className="ms-3" href="https://github.com/ai03-2725/">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a className="ms-3" href="https://discord.gg/qA5JD9y">
                        <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a className="ms-3" href="https://youtube.com/channel/UC2FbYfD2M7kFdmF3-dkGBRA">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                </p>

                <ReactMarkdown children={data.strapiAboutpage.text} className={aboutText} />

            </Container>
        </MainLayout>
    )
}

export const query = graphql`
query {
    strapiAboutpage {
      icon {
        localFile {
          childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
                quality: 100
            )
          }
        }
      }
      text
    }
  }
`

export default AboutPage